<template lang="html">
  <div>
    <div>{{status}}</div>
    <div>
      <button class="btn btn-primary" :disabled="!weixinReady" @click="startRecord">开始录音</button>
      <button class="btn btn-primary" :disabled="!weixinReady" @click="stopRecord">停止录音</button>
      <button class="btn btn-primary" :disabled="!weixinReady" @click="playVoice">播放录音</button>
      <button class="btn btn-primary" :disabled="!weixinReady" @click="pauseVoice">暂停录音</button>
      <button class="btn btn-primary" :disabled="!weixinReady" @click="stopVoice">停止录音</button>
      <button class="btn btn-primary" :disabled="!weixinReady" @click="uploadVoice">上传录音</button>
    </div>
    <div>{{audio}}</div>
    <audio :src="audio" controls></audio>
  </div>
</template>

<script>
  import { UploadBox } from 'common'
  //import UploadBox from '@/components/UploadBox'

  export default {
    components: {
      UploadBox,
    },
    data() {
      return {
        weixinReady: false,
        status: null,
        audio: null
      }
    },
    created() {
      const apiUrl = '/Api/Weixin/Share'
      const config = {
        params: { url: location.href }
      }
      //alert(wx)
      this.$axios.get(apiUrl, config).then((data) => {
        //获取正确的签名数据
        this.$wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.data.appId, // 必填，公众号的唯一标识
          timestamp: data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
          signature: data.data.signature, // 必填，签名，见附录1
          jsApiList: ['startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'pauseVoice', 'stopVoice', 'onVoicePlayEnd', 'uploadVoice'] //必填，需要使用的JS-SDK接口列表
        })
        this.$wx.ready(() => {
          this.weixinReady = true
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        });
        this.$wx.error((res) => {
          alert('微信加载错误：' + res)
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
        this.$wx.onVoiceRecordEnd({
          // 录音时间超过一分钟没有停止的时候会执行 complete 回调
          complete: (res) => {
            var localId = res.localId;
            this.status = 'VoiceRecordEnd'
          }
        });
      }).catch((err) => {
        alert(err)
      })
      //this.$remoteLoad('https://res.wx.qq.com/open/js/jweixin-1.5.0.js').then(() => {
      //})
    },
    computed: {
    },
    methods: {
      startRecord() {
        this.$wx.startRecord()
        this.status = 'startRecord'
      },
      stopRecord() {
        this.$wx.stopRecord({
          success: (res) => {
            var localId = res.localId;
            this.audio = localId
          }
        });
        this.status = 'stopRecord'
      },
      playVoice() {
        this.$wx.playVoice({
          localId: this.audio
        });
        this.$wx.onVoicePlayEnd({
          success: (res) => {
            var localId = res.localId; // 返回音频的本地ID
            this.status = 'VoicePlayEnd'
          }
        });
        this.status = 'playVoice'
      },
      pauseVoice() {
        this.$wx.pauseVoice({
          localId: this.audio
        });
        this.status = 'pauseVoice'
      },
      stopVoice() {
        this.$wx.stopVoice({
          localId: this.audio
        });
        this.status = 'stopVoice'
      },
      uploadVoice() {
        this.$wx.uploadVoice({
          localId: this.audio, // 需要上传的音频的本地ID，由stopRecord接口获得
          isShowProgressTips: 1, // 默认为1，显示进度提示
          success: function (res) {
            var serverId = res.serverId; // 返回音频的服务器端ID
            alert(serverId)
          }
        });
        this.status = 'uploadVoice'
      },
    },
  }
</script>

<style scoped>
</style>
